import gql from 'graphql-tag';

export default gql`
query getTeams {
  getTeams {
    team_id
    name
    description
  }
}
`;
