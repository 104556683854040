<template>
  <b-container>
    <b-tabs
      v-if="allowedToCreateTeams"
      vertical
      nav-wrapper-class="col-3"
      nav-class="mt-5"
      v-model="tabIndex"
      @input="scrollToTop"
      lazy
    >
      <template #tabs-start>
        <b-button
          v-b-modal.modal-create-team class="green-button-transparent mr-3 mb-3 border-0"
          @click="showCreateTeamModal = true"
        >
          <b-icon
            icon="plus-circle-fill"
            class="mr-2 top--3"
            aria-hidden="true"
            scale="1"
          ></b-icon>
          {{ $t('buttons.createTeam') }}
        </b-button>
        <modal-create-new-team
          v-if="showCreateTeamModal"
          class="d-inline-block mb-3"
          @cancel="showCreateTeamModal = false"
          @create-team="createTeam"
        ></modal-create-new-team>
      </template>
      <template #empty>
        <div class="mt-5" v-if="allowedToCreateTeams">
          <p class="text-center" v-html="$t('teamManagement.howToTeamDesc')"></p>
        </div>
        <div class="mt-5" v-else>
          {{ $t('teamManagement.emptyTeam') }}
        </div>
      </template>
      <b-tab v-for="(team, index) in teams" :key="index" lazy>
        <template v-slot:title>
          {{ team.teamName }}
        </template>
        <team-list-view
          class="mt-5"
          :user="user"
          :team="team"
          :team-id="team.teamId"
          @deleteTeam="updateTeamQuery"
        ></team-list-view>
      </b-tab>
    </b-tabs>
    <div v-else class="pt-5">
      <div v-if="!$apollo.loading">
        <p class="text-center" v-html="$t('teamManagement.notAllowed')"></p>
      </div>
    </div>
  </b-container>
</template>

<script>
import CreateTeam from '@/graphQlQueries/mutations/createTeam';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import SetShowHeaderInCache from '@/graphQlQueries/mutations/setShowHeaderInCache';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import SetRunBreadcrumbQueryInCache from '@/graphQlQueries/mutations/setRunBreadcrumbQueryInCache';
import UserAtLeastManagerInOneGroup from '@/graphQlQueries/queries/accessCheckForManagementAreas';
import GetTeams from '@/graphQlQueries/queries/getTeams';
import GetUserByEmail from '@/graphQlQueries/queries/getUserByEmail';

import Auth from '@aws-amplify/auth';

export default {
  name: 'Team',
  components: {
    TeamListView: () => import('@/components/settings/teams/TeamListView.vue'),
    ModalCreateNewTeam: () => import('@/components/modals/team/ModalCreateNewTeam.vue'),
  },
  data: () => ({
    // here you put your component variables
    pageType: 'team-settings',
    allowedToCreateTeams: false,
    newOrChangedTeamId: null,
    showCreateTeamModal: false,
    showEditTeamModal: false,
    userId: null,
    user: null,
    pageId: [],
    teams: [],
    number: 342,
    tabIndex: 0,
  }),
  async created() {
    await this.$apollo.mutate({
      mutation: SetLoadingIndicator,
      variables: {
        isIndicatorLoading: true,
      },
    });
    const currentSession = await Auth.currentSession();
    const idTokenPayload = JSON.parse(currentSession.getIdToken().payload['https://hasura.io/jwt/claims']);
    this.userId = (idTokenPayload['x-hasura-user-id'])
      ? Math.floor(idTokenPayload['x-hasura-user-id'])
      : null;
    if (this.userId) {
      // check if the user is admin
      if (idTokenPayload['x-hasura-allowed-roles'].includes('admin')) {
        this.$currentUserRole = 'admin';
      } else {
        this.$currentUserRole = 'user';
      }
    }
    this.userEmail = Auth.user.attributes.email;
    const {
      data: {
        users: [user],
      },
    } = await this.$apollo.query({
      query: GetUserByEmail,
      variables: {
        email: this.userEmail,
      },
    });
    this.user = {
      userId: this.userId,
      name: user.full_name || null,
      email: Auth.user.attributes.email,
      isSysAdmin: user.sys_admin,
    };
    await this.$apollo.mutate({
      mutation: SetPageTypeInCache,
      variables: {
        type: 'team-settings',
      },
    });
    await this.$apollo.mutate({
      mutation: SetRunBreadcrumbQueryInCache,
      variables: {
        runQuery: true,
      },
    });
    // this mutation is used to display the header of the application
    await this.$apollo.mutate({
      mutation: SetShowHeaderInCache,
      variables: {
        show: true,
      },
    });
  },
  apollo: {
    allowedToCreateTeams: {
      query: UserAtLeastManagerInOneGroup,
      update(data) {
        return data.checkAccessToManagementAreas;
      },
    },
    teams: {
      query: GetTeams,
      update(data) {
        const teamObjects = [];
        if (data.getTeams) {
          data.getTeams.forEach((teamItem) => {
            const element = {
              teamId: teamItem.team_id,
              teamName: teamItem.name,
              teamDescription: teamItem.description,
            };
            teamObjects.push(element);
          });
        }

        teamObjects.sort((a, b) => {
          const textA = a.teamName.toUpperCase();
          const textB = b.teamName.toUpperCase();
          // eslint-disable-next-line no-nested-ternary
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        if (this.newOrChangedTeamId !== null) {
          const newOrChangedTeamIndex = teamObjects.findIndex(
            (team) => team.teamId === this.newOrChangedTeamId,
          );
          if (newOrChangedTeamIndex !== -1) {
            // this is a workaround to update the active tab on create a new team
            // to respect the update cycle of the b-tabs
            this.$nextTick(() => {
              this.$nextTick(() => {
                this.$nextTick(() => {
                  this.$nextTick(() => {
                    requestAnimationFrame(() => {
                      this.tabIndex = newOrChangedTeamIndex;
                      this.newOrChangedTeamId = null;
                    });
                  });
                });
              });
            });
          }
        }

        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
        return teamObjects;
      },
      skip() {
        return !this.userId;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async createTeam(teamObject) {
      await this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      try {
        // insert a new team!
        const {
          data: {
            addTeam: {
              team_id,
            },
          },
        } = await this.$apollo.mutate({
          mutation: CreateTeam,
          variables: {
            obj: {
              name: teamObject.name,
              description: teamObject.desc,
            },
          },
        });
        this.newOrChangedTeamId = team_id;
        this.showCreateTeamModal = false;
        this.number += 1;
        await this.$apollo.queries.teams.refresh();
      } catch (ex) {
        console.error(ex);
      }
    },
    async updateTeamQuery() {
      // reset tab index
      this.tabIndex = 0;
      await this.$apollo.queries.teams.refresh();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}
</style>
