var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _vm.allowedToCreateTeams
        ? _c(
            "b-tabs",
            {
              attrs: {
                vertical: "",
                "nav-wrapper-class": "col-3",
                "nav-class": "mt-5",
                lazy: ""
              },
              on: { input: _vm.scrollToTop },
              scopedSlots: _vm._u(
                [
                  {
                    key: "tabs-start",
                    fn: function() {
                      return [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.modal-create-team",
                                modifiers: { "modal-create-team": true }
                              }
                            ],
                            staticClass:
                              "green-button-transparent mr-3 mb-3 border-0",
                            on: {
                              click: function($event) {
                                _vm.showCreateTeamModal = true
                              }
                            }
                          },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2 top--3",
                              attrs: {
                                icon: "plus-circle-fill",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("buttons.createTeam")) + " "
                            )
                          ],
                          1
                        ),
                        _vm.showCreateTeamModal
                          ? _c("modal-create-new-team", {
                              staticClass: "d-inline-block mb-3",
                              on: {
                                cancel: function($event) {
                                  _vm.showCreateTeamModal = false
                                },
                                "create-team": _vm.createTeam
                              }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "empty",
                    fn: function() {
                      return [
                        _vm.allowedToCreateTeams
                          ? _c("div", { staticClass: "mt-5" }, [
                              _c("p", {
                                staticClass: "text-center",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("teamManagement.howToTeamDesc")
                                  )
                                }
                              })
                            ])
                          : _c("div", { staticClass: "mt-5" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("teamManagement.emptyTeam")) +
                                  " "
                              )
                            ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3260828025
              ),
              model: {
                value: _vm.tabIndex,
                callback: function($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex"
              }
            },
            _vm._l(_vm.teams, function(team, index) {
              return _c(
                "b-tab",
                {
                  key: index,
                  attrs: { lazy: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function() {
                          return [_vm._v(" " + _vm._s(team.teamName) + " ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c("team-list-view", {
                    staticClass: "mt-5",
                    attrs: {
                      user: _vm.user,
                      team: team,
                      "team-id": team.teamId
                    },
                    on: { deleteTeam: _vm.updateTeamQuery }
                  })
                ],
                1
              )
            }),
            1
          )
        : _c("div", { staticClass: "pt-5" }, [
            !_vm.$apollo.loading
              ? _c("div", [
                  _c("p", {
                    staticClass: "text-center",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("teamManagement.notAllowed"))
                    }
                  })
                ])
              : _vm._e()
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }